<div class="container">

    <!-- <p>(WORDING) STEPS - Go through form to have your information on file.</p> -->

    <!-- <button class="btn btn-primary" [appButtonLoading]="LoadingSurvey" (click)="ShowSurvey = true;"
        *ngIf="!LoadingSurvey && !ShowSurvey">Begin</button> -->

    <app-survey [json]="SurveyJSON" [data]="ClientAnswers" (show)="ShowSurvey" showProgressBar="bottom"
        (surveyEvents)="MicroSurveyEvents($event)" [QuestionnaireName]="'preplan'" popup="false">
    </app-survey><!-- *ngIf="ShowSurvey" -->

    <h5 class="card-title mb-5 float-right" [ngClass]="{ 'd-none': Finished }" style="margin-top: -75px;"><strong>Total
            <small>(with
                tax)</small>:</strong>
        <small>&ensp;{{TotalPriceWithTax | currency}}</small>
    </h5>

    <div [ngClass]="{ 'd-none': !Finished }">

        <div id="summarytemp">

            <!-- <button class="btn btn-primary">Back</button> -->
            <!-- After onComplete, go back? Need to find a way to have SurveyJS unmark "complete", but even that - is there an issue completing / saving again? -->

            <h4 class="text-serif font-weight-bold">{{ClientAnswers?.pets_name}}'s Legacy</h4>

            <div class="col-lg-6">

                <div class="card text-serif">
                    <div class="card-body">
                        <ul class="pl-1">
                            <li>You have selected a {{ClientAnswers?.type_of_cremation | lowercase}} cremation.</li>
                            <li>You have selected the {{ClientAnswers?.chosen_urn | titlecase}} urn.</li>

                            <li *ngIf="!ClientAnswers.memorial_items || ClientAnswers.memorial_items.length === 0">You
                                do not wish to order any memorial items.</li>
                            <span *ngIf="ClientAnswers.memorial_items && ClientAnswers.memorial_items.length > 0">
                                <li *ngFor="let m of ClientAnswers.memorial_items">
                                    You have ordered a {{m | titlecase}}.
                                </li>
                            </span>
                            <li *ngIf="!ClientAnswers.keepsake_options || ClientAnswers.keepsake_options.length === 0">
                                You do not wish
                                to order any keepsakes.</li>
                            <span *ngIf="ClientAnswers.keepsake_options && ClientAnswers.keepsake_options.length > 0">
                                <li *ngFor="let k of ClientAnswers.keepsake_options">
                                    You have ordered a {{k | titlecase}}.
                                </li>
                            </span>
                            <!-- <li>You {{ (ClientAnswers?.final_goodbye === 'Yes') ? 'wish' : 'do not wish' }} to have a
                                final
                                goodbye</li>
                            <li>You {{ (ClientAnswers?.create_memorial === 'Yes') ? 'will' : 'will not' }} be holding a
                                memorial service</li> -->
                        </ul>

                        <p>Would you like to process payment now?</p>
                        <div class="d-flex">
                            <button class="btn btn-primary" (click)="ShowStripe = true;">Pre-Pay</button>
                            <button class="btn" (click)="Finished = false;">Pay Later</button>
                        </div>

                        <app-stripe-pay-button *ngIf="ShowStripe" [charge]="TotalPriceWithTax" [id]="QuestionnaireID"
                            [clientInfo]="{ email: ClientAnswers.pet_owner_email, name: ClientAnswers.pet_owner__firstname + ' ' + ClientAnswers.pet_owner_last_name, phone: ClientAnswers.pet_owner_phone_number }"
                            (eventListener)="StripeEvents($event)">
                            <h5 class="card-title text-white mt-3"><strong>Total:</strong>
                                <small>&ensp;{{TotalPriceWithTax | currency}}</small>
                            </h5>
                        </app-stripe-pay-button>
                    </div>
                </div>

            </div>

        </div>

    </div>

    <h5 class="text-center" *ngIf="PaymentSuccess" style="padding-bottom: 300px;">Payment Received<br /><small>Thank
            you</small></h5>

    <!-- <p>Subtotal {{Subtotal | currency}}</p>
    <p>TotalTax {{TotalTax | currency}}</p>
    <p>TotalPriceWithTax {{TotalPriceWithTax | currency}}</p>
    <hr />
    <pre>PriceLookup {{PriceLookup | json}}</pre>
    <hr />
    <p>Current known issue - type_of_cremation NOT CALCULATING IF YOU SELECT CREMATION TYPE BEFORE PET WEIGHT</p>
    <hr />
    <pre>SelectedProductsServices {{SelectedProductsServices | json}}</pre>
    <hr />
    <pre>ClientAnswers {{ClientAnswers | json}}</pre>
    <pre>{{OrderPricingData | json}}</pre> -->

</div>