import { Observable, BehaviorSubject, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { AnalyticService } from './../_directives/analytic/analytic.service';
// import { ServerService } from './../../_services/server.service';
// import { UserService } from './../../_auth/user.service';

@Injectable({
    providedIn: 'root'
})
export class SurveyService implements CanDeactivate<any> {
    private BehaviorSubjects = {
        _Loaded: new BehaviorSubject<any>(false),
        _SurveyEvents: new BehaviorSubject<any>({}),
    };

    public Observables = {
        Loaded$: this.BehaviorSubjects._Loaded.asObservable(),
        SurveyEvents$: this.BehaviorSubjects._SurveyEvents.asObservable(),
    };

    private AdvisorList;
    private IsInFinsym = false;
    private SurveyName;

    constructor(
        private HttpClient: HttpClient,
        private AnalyticService: AnalyticService,
        // private ServerService: ServerService,
        // private UserService: UserService,
    ) { }



    public canDeactivate(component, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean {
        // const user = this.UserService.getUser();

        // return (
        //     !user // Not logged in, can't get to authenticated areas anyway
        //     ||
        //     (user && user.SystemGeneratedPassword !== 1) // Already reset their password  and logged in- allow them to go to dashboard and stuff
        //     ||
        //     nextState.url === '/login' // If the route is /login, they can go there
        //     ||
        //     !this.Survey.IsFrom('new-client') // We call Finish for new-client right before navigating, so IsFrom should be false - we don't want user.SystemGeneratedPassword = 1 to naviate unless they aren't from the wealth profile
        // );

        return true;
    }



    public CheckIfExistsByEmailAddress(surveyName, emailAddress) {
        // this.AnalyticService.send('SurveyService', surveyName + ' CheckIfExistsByEmailAddress', emailAddress);
        // return this.ServerService.post('/questionnaire/CheckIfExistsByEmailAddress/' + surveyName + '/' + emailAddress);
    }



    public Continue(surveyName, data?) {
        // return this.ServerService.post('/questionnaire/Continue/' + surveyName, data).map(response => {
        //     if (response.Success) // If false - don't worry...like when we load the goals summary, this will execute but let's not count it as a "check" unless there is data
        //         this.AnalyticService.send('SurveyService', surveyName + ' Continue / Check'); // Only if there is data to actually continue - the actual loading (and contiuing of the questionnaire) will be in the survey.component.ts LoadSurvey()...we'll send another analytic then

        //     if (response.Questionnaire && response.Questionnaire.ClientID && response.Questionnaire.ClientID < 1000000)
        //         this.IsInFinsym = true;

        //     return response;
        // });
    }



    public FindAdvisorPropertyByCSCEntityID(property, CSCEntityID) {
        let AdvisorName = '';
        if (CSCEntityID)
            // NOTE: Structure changed from AdvisorName and CSCEntityID to text and value - in survey.service.ts - from database to CC data structure
            for (let i = 0; i < this.AdvisorList.length; i++)
                if (this.AdvisorList[i].value === CSCEntityID)
                    AdvisorName = this.AdvisorList[i][property];

        return AdvisorName;
    }



    public Modal(show) {
        const body = document.querySelector('body');
        if (show)
            body.classList.add('micro-survey-active');
        else
            body.classList.remove('micro-survey-active');
    };



    public PrePlanSurvey = {

        GetJSON: (): Observable<any> => {
            // https://blog.angular-university.io/angular-debugging/
            // this.BehaviorSubjects._Loaded.next(false); // This, when calling GetJSONFlattened() caused "expression has changed" issues

            // return this.ServerService.get('/questionnaire/GetWealthProfileOptions').map(response => {
            return new Observable(observer => {
                this.BehaviorSubjects._Loaded.next(true);

                observer.next({

                    JSON: {
                        // "completedHtml": "<h3>Our thoughts are with you during this difficult time.</h3><a href=\"https://petsatpeace.harrisfuneralhome.com/healing-poems-of-love-and-loss/\" target=\"_blank\" class=\"btn btn-primary\">Healing Poems of Love and Loss</a>",
                        "completedHtml": "<h3>Thank you for your pre-plan submission.</h3><p>A confirmation has been sent to your e-mail.  If you need immediate assistance, please contact us at <a href=\"tel:5855442041\" class=\"animated-underline\">(585) 544-2041</a>.</p>",
                        "completeText": "Submit",
                        "pages": [
                            {
                                "name": "page1",
                                "title": "",
                                "elements": [
                                    {
                                        "type": "html",
                                        "name": "question10",
                                        "html": "<h4>Please tell us about your pet.</h4>"
                                    },
                                    {
                                        "type": "text",
                                        "name": "pets_name",
                                        "title": "Pet's Name",
                                        "isRequired": true
                                    },
                                    {
                                        "type": "dropdown",
                                        "name": "type_of_pet",
                                        "startWithNewLine": false,
                                        "title": "Type of Pet",
                                        "isRequired": true,
                                        "choices": [
                                            "Cat",
                                            "Dog",
                                            "Bird",
                                            "Other"
                                        ]
                                    },
                                    {
                                        "type": "text",
                                        "name": "pets_weight",
                                        "title": "Pet's Weight (in lbs)",
                                        "isRequired": true,
                                        "inputType": "number"
                                    },
                                    {
                                        "startWithNewLine": false,
                                        "type": "dropdown",
                                        "name": "pets_gender",
                                        "title": "Pet's Gender",
                                        "choices": [
                                            "Female",
                                            "Male",
                                            "Other"
                                        ]
                                    },
                                    {
                                        "type": "radiogroup",
                                        "name": "type_of_cremation",
                                        "title": "What type of cremation would you like?",
                                        // "defaultValue": "Private",
                                        "choices": [
                                            {
                                                "value": "Private",
                                                "text": "![](/assets/images/private-cremation.jpg)"
                                            },
                                            {
                                                "value": "Semi-Private",
                                                "text": "![](/assets/images/semi-private-cremation.jpg)"
                                            },
                                            {
                                                "value": "Communal",
                                                "text": "![](/assets/images/communal-cremation.jpg)"
                                            }
                                        ],
                                        "isRequired": true,
                                        "colCount": 3
                                    },
                                    {
                                        "type": "html",
                                        "name": "question2",
                                        "html": `<h6>Private Cremation</h6>
                                                <ul>
                                                    <li>A true private cremation – Your pet is the ONLY pet in our cremation unit.</li>
                                                    <li>You have the option to witness a portion of your pet’s cremation. An appointment is required.</li>
                                                    <li>We use a 100% guaranteed ID pet tracking system. Our method is the only one recommended by the Cremation Association of North America.</li>
                                                    <li>We guarantee that your pet’s cremated remains will be available within 72 hours of receiving your pet into our care. (Excludes weekends and holidays).</li>
                                                    <li>Your pet’s cremated remains are gently placed in a custom embroidered velvet pouch and then returned to you in a beautiful wooden urn.</li>
                                                    <li>A cherished ink paw print impression, cremation certificate, and a “Grieving the Lost of Your Pet” guide are included.</li>
                                                </ul>
                                                <ul>
                                                    <li>0-2 lbs: $195</li>
                                                    <li>3-35 lbs: $295</li>
                                                    <li>36-200 lbs: $395</li>
                                                </ul>
                                                <!-- <p>Your pet is the ONLY pet in the cremation machine. We use our Stainless Steel ID Tag to ensure this. This is the same method used for humans and is the only method approved by the Cremation Association of North America. To help with peace of mind, we can even arrange for your family to witness the cremation process at no additional cost.</p> -->`
                                    },
                                    {
                                        "type": "html",
                                        "name": "question4",
                                        "startWithNewLine": false,
                                        "html": `<h6>Semi-Private Cremation</h6>
                                                <ul>
                                                    <li>Your pet is placed in the cremation unit along with other pets. However, our unique stainless steel dividers ensure that your pet is completely segregated from the others. Pets are NEVER touching. We guarantee you will receive your pet’s cremated remains only.</li>
                                                    <li>We use a 100% guaranteed ID pet tracking system. Our method is the only one recommended by the Cremation Association of North America.</li>
                                                    <li>We guarantee that your pet’s cremated remains will be available within 72 hours of receiving your pet into our care. (Excludes weekends and holidays).</li>
                                                    <li>You’re pet’s cremated remains are gently placed in a custom embroidered velvet pouch and then returned to you in a beautiful wooden urn.</li>
                                                    <li>A cherished ink paw print impression, cremation certificate, and a “Grieving the Loss of Your Pet” guide are included.</li>
                                                </ul>
                                                <ul>
                                                    <li>0-2 lbs: $85</li>
                                                    <li>3-35 lbs: $165</li>
                                                    <li>36-200 lbs: $215</li>
                                                </ul>
                                        <!-- <p>Your pet is placed in the cremation machine along with other pets.  However, your pet is segregated from other pets using our unique stainless steel dividers making sure the pets never touch.  We also use our Stainless Steel ID Tag to ensure your pet is returned. This is the same method used for humans and is the only method approved by the Cremation Association of North America. We guarantee you will receive your pets cremated remains only.</p> -->`
                                    },
                                    {
                                        "type": "html",
                                        "name": "question3",
                                        "startWithNewLine": false,
                                        "html": `<h6>Communal Cremation</h6>
                                                <ul>
                                                    <li>Communal cremation is the process of cremating multiple pets together without any form of separation. The commingled cremated remains are not returned to the pet owners(s) and will be disposed of in a non-recoverable manner.</li>
                                                    <li>Your pet will be transferred from your Veterinary Hospital or home in a timely manner.</li>
                                                    <li>Payment in full is required at the time of death.</li>                                                    
                                                    <li>Once you have selected the cremation option that is best for your pet, it is important to determine the best way to get your pet into our care.</li>
                                                </ul>
                                                <ul>
                                                    <li>0-2 lbs: $55</li>
                                                    <li>3-35 lbs: $65</li>
                                                    <li>36-200 lbs: $105</li>
                                                </ul>
                                        <!-- <p>Is a cremation procedure where multiple pets are cremated together without any form of separation. These commingled cremated remains are not returned to the owners. Because of the lack of regulation, it is important for you to ask your pet cremation provider what they mean when they use the terms like \"private cremation\" or \"individual cremation\"</p>. -->`
                                    },
                                    {
                                        "type": "radiogroup",
                                        "name": "witness_cremation",
                                        "title": "Would you like opportunity to witness the cremation?",
                                        "visibleIf": "{type_of_cremation} = 'Private'",
                                        "isRequired": true,
                                        "choices": [
                                            {
                                                "value": "Yes",
                                                "text": "Yes"
                                            },
                                            {
                                                "value": "No",
                                                "text": "No"
                                            }
                                        ],
                                        "colCount": 3
                                    }
                                ],
                                "description": ""
                            },
                            {
                                "name": "page2",
                                "title": "Urn Selection",
                                "elements": [
                                    {
                                        "type": "radiogroup",
                                        "name": "chosen_urn",
                                        "title": "Which urn would you like?",
                                        "description": "Engraving is available at an additional cost. Please contact us with special requests or to discuss options and pricing.",
                                        "defaultValue": "Standard (included)",
                                        "isRequired": true,
                                        "choices": [
                                            {
                                                "value": "Classic Paw - Slate",
                                                "text": "![](assets/images/urns/classic-paw-slate.jpg) The Slate Classic Paw has a slate finish and features a ring of pewter paw prints encircling the urn."
                                            },
                                            {
                                                "value": "Maus - Granite",
                                                "text": "![](assets/images/urns/maus-granite.jpg) The Maus Granite has a speckled black and gray-blue finish combined beautifully with a bronze accent ring beneath the lid."
                                            },
                                            {
                                                "value": "Odyssey - Crimson",
                                                "text": "![](assets/images/urns/odyssey-crimson.jpg) The Odyssey® Crimson pet urn has a high gloss beautiful red finish with bronze accents and paw prints encircling the urn."
                                            },
                                            {
                                                "value": "Odyssey - Pearl",
                                                "text": "![](assets/images/urns/odyssey-pearl.jpg) The Odyssey® Pearl pet urn has a high gloss pearl white finish with pewter accents and paw prints encircling the urn."
                                            },
                                            {
                                                "value": "Photo Cube - Navy",
                                                "text": "![](assets/images/urns/photo-cube-navy.jpg) The Navy Photo Cube accommodates three pictures and has a separate cremation compartment."
                                            },
                                            {
                                                "value": "Ceramic - Blue",
                                                "text": "![](assets/images/urns/ceramic-blue.jpg) This urn features a deep blue finish with a natural band of hand-painted paw prints that encircle the urn."
                                            },
                                            {
                                                "value": "Laser Engraved Photo Urn",
                                                "text": "![](assets/images/urns/engraved-urn.png) We will engrave your pet’s name, dates, and a photo on the top of one of our beautiful wooden urns."
                                            },
                                            {
                                                "value": "Black Photo Box",
                                                "text": "![](assets/images/urns/urn-black.jpg) The Black Photo box features a black finish and one photo slot which is separate from the cremation compartment."
                                            },
                                            {
                                                "value": "Standard (included)",
                                                "text": "![](assets/images/urns/standard.jpg) This urn comes standard with our Private and Semi-Private cremation options."
                                            },
                                        ],
                                        "colCount": 3
                                    }
                                ],
                                "visibleIf": "{type_of_cremation} <> 'Communal'",
                            },
                            {
                                "name": "page3",
                                "title": "Memorial Items",
                                "elements": [
                                    {
                                        "type": "checkbox",
                                        "name": "memorial_items",
                                        "title": "Would you like any memorial items?",
                                        "defaultValue": "none",
                                        "hasNone": true,
                                        "noneText": "No Memorial Items",
                                        "choices": [
                                            {
                                                "value": "Clay Paw Print",
                                                "text": "![](assets/images/memorial-items/clay-paw-print.jpg) An everlasting impression of your pet’s paw print and name."
                                            },
                                            {
                                                "value": "Fur Clipping",
                                                "text": "![](assets/images/memorial-items/fur-clipping.jpg) A clipping of your beloved pet’s fur in our “Forever in My Heart” holder."
                                            },
                                            {
                                                "value": "Rainbow Bridge Candle",
                                                "text": "![](assets/images/memorial-items/rainbow-bridge-candle.jpg) We will create a glass-sided memorial candle with your pet’s name and photo."
                                            },
                                        ],
                                        "colCount": 3
                                    },

                                    // https://surveyjs.io/Documentation/Survey-Creator?id=Create-Custom-Widget

                                    // {
                                    //     "type": "matrixdropdown",
                                    //     "name": "memorial_items",
                                    //     "title": "Do you want any Memorial Items?",
                                    //     "columns": [
                                    //         {
                                    //             "name": "Size",
                                    //             "cellType": "dropdown",
                                    //             "choices": [
                                    //                 {
                                    //                     "value": "Small",
                                    //                     "text": "Small"
                                    //                 },
                                    //                 {
                                    //                     "value": "Medium",
                                    //                     "text": "Medium"
                                    //                 },
                                    //                 {
                                    //                     "value": "Large",
                                    //                     "text": "Large"
                                    //                 }
                                    //             ]
                                    //         },
                                    //         {
                                    //             "name": "Quantity",
                                    //             "cellType": "text",
                                    //             "width": "100px"
                                    //         },
                                    //         {
                                    //             "name": "Add",
                                    //             "cellType": "checkbox"
                                    //         }
                                    //     ],
                                    //     "choices": [
                                    //         "Add"
                                    //     ],
                                    //     "rows": [
                                    //         {
                                    //             "value": "Classic Pet",
                                    //             "text": "![](https://petsatpeace.harrisfuneralhome.com/8750/High/Ceramic_Blue.jpg)"
                                    //         },
                                    //         {
                                    //             "value": "Another",
                                    //             "text": "![](https://petsatpeace.harrisfuneralhome.com/8750/High/Ceramic_Blue.jpg)"
                                    //         }
                                    //     ]
                                    // }

                                ]
                            },
                            {
                                "name": "page4",
                                "title": "Keepsakes",
                                "elements": [
                                    // {
                                    //     "type": "radiogroup",
                                    //     "name": "keepsake_yes_no",
                                    //     "title": "Would you like any keepsakes?",
                                    //     "choices": [
                                    //         "Yes",
                                    //         "No"
                                    //     ],
                                    //     "colCount": 2
                                    // },
                                    {
                                        "type": "checkbox",
                                        "name": "keepsake_options",
                                        // "visibleIf": "{keepsake_yes_no} = 'Yes'",
                                        "title": "Would you like any keepsakes?",
                                        "defaultValue": "none",
                                        "hasNone": true,
                                        "noneText": "No Keepsakes",
                                        "choices": [
                                            {
                                                "value": "Stainless Steel Paw Bracelet with Paw Charm",
                                                "text": "![](assets/images/keepsakes/paw-bracelet-with-paw-charm.jpg) <div class=\"description\">Stainless Steel Paw Bracelet with Paw Charm</div>"
                                            },
                                            {
                                                "value": "Stainless Steel Paw Print Dog Tag",
                                                "text": "![](assets/images/keepsakes/dog-tag-with-actual-paw-print.png) <div class=\"description\">Stainless Steel Paw Print Dog Tag with 24 inch Chain</div>"
                                            },
                                            {
                                                "value": "Stainless Steel Paw Prints Keychain",
                                                "text": "![](assets/images/keepsakes/stainless-steel-paw-prints-pet-keychain.png) <div class=\"description\">Stainless Steel Paw Prints Keychain</div>"
                                            },
                                            {
                                                "value": "Onyx Paw Print Cylinder",
                                                "text": "![](assets/images/keepsakes/onyx-cylinder-with-paw-prints.jpg) <div class=\"description\">Onyx Paw Print Cylinder with 19 inch Chain</div>"
                                            },
                                            {
                                                "value": "Sterling Silver Paw Print Heart Pendant",
                                                "text": "![](assets/images/keepsakes/heart-pendant-with-actual-paw-print.png) <div class=\"description\">Sterling Silver Paw Print Heart Pendant with 20 inch Chain</div>"
                                            },
                                            {
                                                "value": "Stainless Steel Pewter Hinged Paw Pendant",
                                                "text": "![](assets/images/keepsakes/pewter-hinged-paw-pendant.jpg) <div class=\"description\">Stainless Steel Pewter Hinged Paw Pendant with 19 inch Chain</div>"
                                            },
                                        ],
                                        "colCount": 3
                                    },
                                    // {
                                    //     "type": "text",
                                    //     "name": "keepsake_quantity",
                                    //     "title": "Keepsake Quantity",
                                    //     // "visibleIf": "{keepsake_yes_no} = 'Yes'"
                                    // }
                                ]
                            },
                            // {
                            //     "name": "page5",
                            //     "title": "Final Goodbye",
                            //     "elements": [
                            //         {
                            //             "type": "radiogroup",
                            //             "name": "final_goodbye",
                            //             "title": "I would like a final goodbye",
                            //             "choices": [
                            //                 "Yes",
                            //                 "No"
                            //             ],
                            //             "colCount": 2
                            //         }
                            //     ],
                            //     "description": "Many pet parents along with their families want to have a one last final goodbye after the death of their pet. In most families, the death of a pet can be everyone's first experience. We encourage everyone, including other pets, to participate in the final goodbye. It is proven that by having a private goodbye helps many families through grief and with closure. Please know that our staff has been trained in the proper procedures and care techniques to ensure that your pet is groomed and presentable for the final goodbye. Please know that our staff has been trained in the proper procedures and care techniques to ensure that your pet is groomed and presentable for the final goodbye."
                            // },
                            // {
                            //     "name": "page6",
                            //     "title": "Your Pet's Memorial",
                            //     "elements": [
                            //         {
                            //             "type": "radiogroup",
                            //             "name": "create_memorial",
                            //             "title": "Do you wish to create a memorial of your pet?",
                            //             "choices": [
                            //                 "Yes",
                            //                 "No"
                            //             ],
                            //             "colCount": 2
                            //         }
                            //     ],
                            //     "description": "There are many ways to memorialize & remember your pet when they pass. We offer many different products, such as Laser Photo Urns, Clay Paw Prints, Fur Clippings & Rainbow Bridge Candles etc. We also offer free On line Memorials or Pet Obituaries. Our on line memorials allow you to write your own remembrance and upload photos. This lifetime archive will always be visible on our website, you can even share it on Facebook for family and friends to see it."
                            // },
                            {
                                "name": "page7",
                                "title": "Summary",
                                "elements": [
                                    {
                                        "type": "radiogroup",
                                        "name": "time_frame",
                                        "title": "Time Frame",
                                        "choices": [
                                            "Contact me to answer any further questions or schedule an appointment.",
                                            "Place my pet's information on file."
                                        ]
                                    },
                                    {
                                        "type": "text",
                                        "name": "pet_owner_first_name",
                                        "title": "First Name",
                                        "isRequired": true
                                    },
                                    {
                                        "type": "text",
                                        "name": "pet_owner_last_name",
                                        "startWithNewLine": false,
                                        "title": "Last Name",
                                        "isRequired": true
                                    },
                                    {
                                        "type": "text",
                                        "name": "pet_owner_email",
                                        "title": "Your Email",
                                        "isRequired": true,
                                        "inputType": "email"
                                    },
                                    {
                                        "type": "text",
                                        "name": "pet_owner_phone_number",
                                        "startWithNewLine": false,
                                        "title": "Your Phone Number",
                                        "isRequired": true,
                                        "inputType": "text",
                                        "inputFormat": "(999) 999-9999"
                                    },
                                    {
                                        "type": "text",
                                        "name": "address",
                                        "title": "Address",
                                        "isRequired": true
                                    },
                                    {
                                        "type": "text",
                                        "name": "city",
                                        "startWithNewLine": false,
                                        "title": "City",
                                        "isRequired": true
                                    },
                                    {
                                        "type": "text",
                                        "name": "state",
                                        "title": "State",
                                        "isRequired": true
                                    },
                                    {
                                        "type": "text",
                                        "startWithNewLine": false,
                                        "name": "zip",
                                        "title": "Zip",
                                        "isRequired": true
                                    },
                                    {
                                        "type": "html",
                                        "name": "question9",
                                        "html": "<div id=\"surveysummary\"></div>", // <div id=\"StripePayButtonComponent\" class=\"survey-dynamic-component-container\" data-options='{ \"SizeClasses\": \"col-12\", \"uploadToRoute\": \"/user/upload\" }'>
                                        // "html": "<div class=\"card\"><div class=\"card-header\">Summary</div><div class=\"card-body\"><div id=\"surveysummary\"></div><!-- <a href=\"https://petsatpeace.harrisfuneralhome.com/healing-poems-of-love-and-loss/\" target=\"_blank\" class=\"btn btn-primary\">Healing Poems of Love and Loss</a><small>Opens in a new window</small> --></div></div></div>"
                                    }
                                ]
                            },
                            // {
                            //     "name": "page8",
                            //     "elements": [
                            //         {
                            //             "type": "html",
                            //             "name": "question9",
                            //             "html": "<div class=\"card\"><div class=\"card-header\">Summary</div><div class=\"card-body\"><h5 class=\"card-title\">Question</h5><p class=\"card-text\">{type_of_cremation} {chosen_urn}</p><div id=\"surveysummary\"></div></div><a href=\"https://petsatpeace.harrisfuneralhome.com/healing-poems-of-love-and-loss/\" target=\"_blank\" class=\"btn btn-primary\">Healing Poems of Love and Loss</a></div></div>"
                            //         }
                            //     ]
                            // }
                        ]
                    }

                });

                // };

            });

        },

        GetJSONFlattened: (existing?): Observable<any> => {
            return this.PrePlanSurvey.GetJSON()
                .pipe(
                    map(response => {

                        const pages = response.JSON.pages;
                        let newPages = [
                            {
                                name: '',
                                title: '',
                                elements: [],
                            },
                        ];

                        for (let i = 0; i < pages.length; i++) {
                            if (i === 0 && existing && this.IsInFinsym) // If an existing client, remove "Basic Information", page 1, from the wealth profile questions
                                continue;

                            for (let x = 0; x < pages[i].elements.length; x++)
                                newPages[0].elements.push(pages[i].elements[x]);
                        }

                        response.JSON.pages = newPages;

                        return response;
                    })
                );
        },

    }



    public Register = {

        // Client: (surveyName, data) => {
        //     if (data && data.QuestionnaireData && data.QuestionnaireData.preferred_advisor) // Will only have preferred_advisor at this stage for super users (clients won't get this question until later pages...they don't START with one long page like a super user does)
        //         data.QuestionnaireData.preferred_advisor_text = this.FindAdvisorPropertyByCSCEntityID('text', data.QuestionnaireData.preferred_advisor); // In Survey.Save too
        //     this.SurveyName = surveyName;
        //     this.AnalyticService.send('SurveyService', this.SurveyName + ' Register Client', this.Survey.FindEmailAddress(data));
        //     // return this.ServerService.post('/questionnaire/RegisterClient/' + surveyName, data);
        // },

        // Sharefile: (data) => {
        //     this.AnalyticService.send('SurveyService', this.SurveyName, 'Register Sharefile', this.Survey.FindEmailAddress(data));
        //     // return this.ServerService.post('/questionnaire/RegisterSharefile', data);
        // },

        // Yodlee: (data) => { // only passing data for analytics
        //     this.AnalyticService.send('SurveyService', this.SurveyName, 'Register Yodlee', this.Survey.FindEmailAddress(data));
        //     // return this.ServerService.post('/questionnaire/RegisterYodlee', data);
        // },

    };



    public SendEvent(data) {
        // So you can subscribe to these events with more than just the event emitter from micro-survey.component.ts (listen at a global level) (but I'll have them fire the same)
        this.BehaviorSubjects._SurveyEvents.next(data);
    }



    public Survey = {

        // FindEmailAddress: (data) => {
        //     return (data && data.PII && data.PII.client_1_email_address) ? data.PII.client_1_email_address : '';
        // },

        // Finish: (SurveyName) => {
        //     this.AnalyticService.send('SurveyService', this.SurveyName, 'Finish');
        //     localStorage.removeItem(SurveyName + 'Start');
        // },

        // IsFrom: (SurveyName) => {
        //     const IsFrom = localStorage.getItem(SurveyName + 'Start') === 'true';
        //     if (IsFrom)
        //         this.AnalyticService.send('SurveyService', this.SurveyName, 'IsFrom');
        //     return IsFrom;
        // },

        Save: (data) => {
            // this.AnalyticService.send('SurveyService', this.SurveyName, 'Save', this.Survey.FindEmailAddress(data));
            return this.HttpClient.post('/questionnaire', { data: data });
        },

        // Start: (SurveyName) => {
        //     this.AnalyticService.send('SurveyService', this.SurveyName, 'Start');
        //     localStorage.setItem(SurveyName + 'Start', 'true');
        // },

    }



}
