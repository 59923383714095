<img src="/assets/images/logo.jpg" alt="Pre-Plan by Harris Funeral Home"
    style="height: auto; margin: 0 auto; width: 350px;" />

<h6 class="m-auto text-center my-4" style="line-height: 1.5em; max-width: 650px;">Pets at Peace is here to treat your
    loved
    one
    with care and make this
    process as simple as possible for you. Start by filling out the pre-planning form below.</h6>

<router-outlet>
    <app-spinner></app-spinner>
</router-outlet>